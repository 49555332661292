// SVG text → path converter
import * as SvgTextToPath from 'svg-text-to-path/browser.js';
import map from 'svg-text-to-path/handlers/map.js';

// SVG 2 PDF converter
import { jsPDF } from 'jspdf'
import 'svg2pdf.js'

import * as JSZip from 'jszip';

import { saveAs } from 'file-saver';

export function svgToPdfCovert(x, y, width, height, canvasWidth, canvasHeight, fileName, pdfName, textWithLinkX, textWithLinkY, plateHeight, instanceName, templateName, isBulk, objArr) 
{
    let buffCopy = document.querySelector('#' + templateName).outerHTML;
    buffCopy = buffCopy.replace(/(preserveAspectRatio="xMidYMin meet")/gi,'preserveAspectRatio="xMinYMin meet"');
    buffCopy = buffCopy.replace(/<g id="requirements".*/g, '');
    buffCopy = buffCopy.replace(/(id="[^"]*)"/g, '');
    document.querySelector('#' + instanceName).innerHTML = document.querySelector('#' + templateName).innerHTML;      

    SvgTextToPath.replaceAll(document.querySelector('#' + instanceName), 
    {
    handlers: [map],
    fontMap: 
    {
        "DniproCity": {
            "400": "/fonts/DniproCity/400.otf",                  
        },
        "DniproCity-Regular": {
            "400": "/fonts/DniproCity/400.otf",                  
        },
        "DniproCity-Medium": {
            "400": "/fonts/DniproCity/500.otf",
        },
        "DniproCity-SemiBold": {
            "400": "/fonts/DniproCity/600.otf",
        },
        "DniproCity-Bold": {
            "400": "/fonts/DniproCity/700.otf",
        },
        "Roboto": {
            "400": "/fonts/Roboto/400.ttf",
        }
    },
    group: true
    })
    .then(() => {
    // PDF document setup
    var orientation = canvasWidth > canvasHeight ? 'l' : 'p';

    const doc = new jsPDF({
    orientation: orientation,
    unit: 'mm',
    format: [canvasWidth, canvasHeight],
    putOnlyUsedFonts:true,
    compress: true})

    const element = document.getElementById(instanceName);
    doc.setTextColor(61, 39, 153);
    doc.textWithLink('http://streetsigns.dnipro.design', textWithLinkX, textWithLinkY, { url: 'http://streetsigns.dnipro.design'});
    doc.svg(element, {
        x,
        y,
        width,
        height
    })
    .then(() => {
        if(isBulk)
        {
            let isCopy = false;

            if(typeof objArr.length !== 'undefined')
            {
                for(let i = 0; i < objArr.length; i++)
                {
                    if(objArr[i].pdfName == (fileName + pdfName))
                    {
                        isCopy = true;
                    }  
                }
            }

            if(!isCopy)
            {
                let buffObj = {exportDoc: doc, pdfName: fileName + pdfName, svgPrev: buffCopy, svgSizeWidth: width, svgSizeHeight: plateHeight};
                objArr.push(buffObj);
            }
        }
        else
        {
            // Save pdf
            doc.save(fileName + pdfName);
        }
    })
    })
}

export function pdfBulkDownload(objArr)
{
    if(typeof objArr.length !== 'undefined')
    {
        if(objArr.length > 0)
        {
            var zip = new JSZip();
            let blobArray = [];
            const d = new Date();
            let zipName = 'dnipro-street-signs' + '_' + d.getDate() + (d.getMonth() + 1) + d.getFullYear() + d.getHours() + d.getMinutes() + d.getSeconds() + '.zip';

            for(let i = 0; i < objArr.length; i++)
            {
                blobArray[i] = objArr[i].exportDoc.output('blob');
                zip.file(objArr[i].pdfName, blobArray[i]);
            }

            zip.generateAsync({type:"blob"})
            .then(function(content) {
                saveAs(content, zipName);
            });

            for(let i = objArr.length; i > 0; --i)
            {
                objArr.pop();
            }
        }
    }
}