import { reactive } from 'vue'

export const store = reactive({
  inputStreetName: '',
  streetData: {},
  inputNumber: '',
  mainNumber: '',
  leftNumber: '',
  rightNumber: '',

  dirLeft: false,
  dirForward: true,
  dirRight: false,

  dirEntrLeft: false,
  dirEntrForward: true,
  dirEntrRight: false,

  entrObjType: {id: '1', text: 'квартири', textTranslate: 'apartments'},
  entrType: {id: 1, text: 'корпус', textTranslate: 'entrance'},

  partAStartInput: '',
  partAEndInput: '',
  partBStartInput: '',
  partBEndInput: '',

  partAStart: '',
  partAEnd: '',
  partBStart: '',
  partBEnd: '',

  entranceNumberInput: '',
  entranceNumber: '',

  aptStartInput: '',
  aptEndInput: '',

  aptStart: '',
  aptEnd: '',
  
  fixersDisplay: false,
  sizeMultiplier: false,

  bulkData: [], //Array of objects {exportDoc: , pdfName: , svgPrev: , svgSizeWidth: , svgSizeHeight: }

  isCartEmpty: true,
})