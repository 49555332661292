<template lang="pug">
g.requirements(
  :transform="'translate('+ sizeM(16) +','+ sizeM(this.plateHeight+50) +')'"
  )
  text(
    x="0" 
    y="0" 
    fill="#000" 
    style="font-size: 9px; font-weight: 400; font-family: Roboto;"
    ) {{plateName}}
    tspan(
      x="0" 
      y="12" 
      fill="#000" 
      style="font-size: 12px; font-weight: 400; font-family: Roboto;"
      ) {{ sizeM(sizeXR(0)) }}x{{ sizeM(this.plateHeight) }} мм

  g(
    v-if="this.isWhiteBack",
    transform="translate(0,22)"
    )
    g(
      transform="translate(0,0)" 
      style="font-size: 7px; font-weight: 400; font-family: Roboto;"
      )
      circle(
        cx="5" 
        cy="5" 
        r="5" 
        fill="#0075c9"
        )
      text(
        x="15" 
        y="7"
        ) Orafol 751C:053 / Pantone 299
    
    g(
      transform="translate(0,12)" 
      style="font-size: 7px; font-weight: 400; font-family: Roboto;"
      )
      circle(
        cx="5" 
        cy="5" 
        r="5" 
        fill="#000"
        )
      text(
        x="15" 
        y="7"
        ) Orafol 751C:070 / Pantone Black
    
    g(
      transform="translate(0,24)" 
      style="font-size: 7px; font-weight: 400; font-family: Roboto;"
      )
      circle(
        cx="5" 
        cy="5" 
        r="5" 
        fill="#fff" 
        stroke-width=".5" 
        stroke="#000"
        )
      text(
        x="15" 
        y="7"
        ) Біла основа
        tspan(
          x="15" 
          dy="9"
          ) RAL 9003 / Pantone White
        tspan(
          x="15" 
          dy="9" 
          font-size="5px"
          ) Рекомендований матеріал основи
        tspan(
          x="15" 
          dy="7" 
          font-size="5px"
          ) Композитні панелі (АКП)

  g(
    v-else,
    transform="translate(0,22)"
    )
    g(
      transform="translate(0,12)" 
      style="font-size: 7px; font-weight: 400; font-family: Roboto;"
      )
      circle(
        cx="5" 
        cy="5" 
        r="5" 
        fill="#fff"
        stroke-width=".5" 
        stroke="#000"
        )
      text(
        x="15" 
        y="7"
        ) Orafol 751C:010 / Pantone White 
    
    g(
      transform="translate(0,24)" 
      style="font-size: 7px; font-weight: 400; font-family: Roboto;"
      )
      circle(
        cx="5" 
        cy="5" 
        r="5" 
        fill="#0075c9"         
        )
      text(
        x="15" 
        y="7"
        ) Лазурова основа 
        tspan(
          x="15"
          dy="9"
          ) RAL 5015 / Pantone 300
        tspan(
          x="15" 
          dy="9" 
          font-size="5px"
          ) Рекомендований матеріал основи
        tspan(
          x="15" 
          dy="7" 
          font-size="5px"
          ) Композитні панелі (АКП)

  text(
    x="0" 
    y="95" 
    fill="#000" 
    text-anchor="start" 
    style="font-size: 7px; font-weight: 400; font-family: Roboto;"
    ) розроблено 
    tspan(
      style="font-size: 7px; font-weight: 400; font-family: DniproCity-Bold;"
      ) dnipro.design
  g#logoDnipro(
    :transform="'translate('+ sizeM(sizeXR(150))+',0), scale(.1)'")              
    path(d="M51.6 37.4c-7.4 3.5-14.4 6.8-25.4 6.8-8.5 0-14.5-1.9-20.9-4.8-1.4-.6-2.8-1.3-4.2-2L0 36.9v44.3c0 42 51 54.9 51 54.9s51-12.8 51-54.9V33.5c-6.6-2.6-13.7-3.9-20.8-3.9-13.2 0-21.5 4-29.6 7.8zm37.7 43.8c0 12.7-6.5 23.5-19.3 32.2-5.9 3.9-12.3 7-19 9.3-9.7-3.2-38.2-15-38.2-41.5v-24c4.4 1.4 8.9 2 13.5 2 11 0 17.9-3.3 25.4-6.9 8.1-3.8 16.4-7.8 29.6-7.8 2.7 0 5.4.2 8.1.5v36.2zm0-80.6c4.4.6 8.7 1.7 12.8 3.3v15c-4.1-1.6-8.4-2.7-12.8-3.3-2.7-.4-5.4-.6-8.1-.6-13.2 0-21.5 4-29.6 7.8-7.4 3.5-14.4 6.9-25.4 6.9-4.5 0-9.1-.7-13.4-2-4-1.3-8-3-11.7-4.9l-1-.5v-15l1 .5c3.8 1.9 7.7 3.5 11.7 4.8 4.3 1.3 8.9 2 13.4 2 11 0 18-3.3 25.4-6.8C59.6 4 68 0 81.1 0c2.8 0 5.5.2 8.2.6zm117.3 45.8 1.3-12.2h-55.1v12.2h16.9l-25.2 58.8H136v30.2h13.4v-18h52.7l-4 18h13.4l6.9-30.2h-11.8V46.4zm-13.5 58.8h-35.4l25.9-58.8h9.5v58.8zM268 53.3l13.2-1.9v66.1H268V90.1h-27.4v27.4h-13.2V53.2h13.2v25.3H268V53.3zm31.8-.1 13.2-2.4v66.7h-13.2V64.6h-9.4V54.5l9.4-1.3zm2.4-26.8 14.1 1.7-3 14.3-13 1.9 1.9-17.9zM368 53.2l13.2-1.9v66.2H368V64.6h-26v52.8h-13.2V53.2H368zm54.1-2.6c-5.7 0-11.3 1.7-16.2 4.8l-5.6-2.8H397v83.5h13.2V120c24.2-.1 42.3-16.6 42.3-38.3-.1-19.2-12.2-31.1-30.4-31.1zm-12 57.2V66.3c3.2-2.3 7.1-3.6 11-3.7 9.3 0 18 6.1 18 19.1 0 14.8-10.5 26-29 26.1zm82.1-57.2c-20.7 0-32.4 16.6-32.4 37 0 16.7 10.7 32.4 31.1 32.4 20.7 0 32.4-16.6 32.4-37 0-16.7-10.7-32.4-31.1-32.4zm-1.3 57.3c-12.1 0-17.8-10.3-17.8-21.3 0-14.6 6.1-23.9 19.1-23.9C504.3 62.7 510 73 510 84c.1 14.7-6.1 23.9-19.1 23.9z"
    )
    path(
      style="fill:none" 
      d="M0 0h727.11v340.95H0z"
      )
  text(
    :x="sizeM(sizeXR(150))", 
    y="29" 
    style="font-size: 7px; font-weight: 400; font-family: Roboto;"
    ) Ліцензія матеріалів —
    tspan(
      :x="sizeM(sizeXR(150))", 
      dy="9"
      ) CC BY-ND 4.0
    tspan(
      :x="sizeM(sizeXR(150))", dy="9"
      ) Із Зазначенням Авторства —
    tspan(
      :x="sizeM(sizeXR(150))", 
      dy="9"
      ) Без Похідних 4.0 Міжнародна
</template>
<script>
import { store } from '@/store.js';

export default {
  name: 'SvgRequirements',
  props:["plateWidth", "plateHeight", "plateName", "isWhiteBack", "immutableSize"],

  data() {
    return{
      store,
    }
  },

  methods: {
    sizeM(size)
    {
      if(this.immutableSize)
      {
        return size;
      }
      else
      {
        if(this.store.sizeMultiplier)
        {
          return size;
        }
        else
        {
          return size * 0.75;
        }
      }
    },

    sizeXR(offset)
    {
      return this.plateWidth - offset;
    },
  },
 }
</script>