<template lang="pug">
defs
  linearGradient(
    id="plateGradient" 
    gradientTransform="rotate(90)"
    )
    stop(
      offset="30%" 
      stop-color="#fff"
      )
    stop(
      offset="60%" 
      stop-color="#F5F6F8"
      )
    stop(
      offset="95%"  
      stop-color="#e6e9ed"
      )
  linearGradient(
    id="plateGradientBlue" 
    gradientTransform="rotate(90)"
    )
      stop(
        offset="0%" 
        stop-color="#3695d9"
        )
      stop(
        offset="30%" 
        stop-color="#0075c9"
        )
      stop(
        offset="95%"  
        stop-color="#0075c9"
        )
  linearGradient(
    id="reflex"
    gradientTransform="rotate(90)"
    )                        
    stop(
      offset="41%"  
      stop-color="#fff" 
      stop-opacity="0"
      )
    stop(
      offset="42%" 
      stop-color="#fff" 
      stop-opacity=".4"
      )
    stop(
      offset="90%" 
      stop-color="#fff" 
      stop-opacity=".0"
      )
  linearGradient(
    id="edge" 
    gradientTransform="rotate(90)"
    )   
    stop(
      offset="1%"
      stop-color="#fff"
      stop-opacity="1"
      )
    stop(
      offset="8%"
      stop-color="#fff"
      stop-opacity=".5"
      )
    stop(
      offset="98%"
      stop-color="#000"
      stop-opacity=".1"
      )
    stop(
      offset="100%"
      stop-color="#000"
      stop-opacity=".4"
      )
  linearGradient(
    id="edgeBlue" 
    gradientTransform="rotate(90)"
    )                        
    stop(
      offset="1%"  
      stop-color="#fff" 
      stop-opacity=".2")
    stop(
      offset="8%"  
      stop-color="#fff" 
      stop-opacity=".1"
      )
    stop(
      offset="98%"  
      stop-color="#000" 
      stop-opacity=".1"
      )
    stop(
      offset="100%" 
      stop-color="#000" 
      stop-opacity=".3"
      )
  clipPath#waveClip
    rect(      
      :x="sizeM(0)" 
      y="0",
      :width="sizeXR(this.clipWidth)",
      :height="sizeM(20)"
      )

filter#svgDropShadow
  feDropShadow(
    dx="0" 
    dy="2" 
    stdDeviation="3" 
    flood-color="#000" 
    flood-opacity=".4")
  feDropShadow(
    dx="0" dy="4" 
    stdDeviation="10" 
    flood-color="#000" 
    flood-opacity=".3")

filter#svgBlur
  feGaussianBlur(
    in="SourceGraphic"
    stdDeviation="6"
    )
</template>
<script>
import { store } from '@/store.js';

export default {
  name: 'SvgDefs',
  props:["plateWidth", "clipWidth",],

  data() {
    return{
      store,
    }
  },

  methods: {
    sizeM(size)
    {
      if(this.store.sizeMultiplier)
      {
        return size;
      }
      else
      {
        return size * 0.75;
      }
    },

    sizeXR(offset)
    {
      return this.plateWidth - offset;
    },
  }
 }
</script>