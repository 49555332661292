<template lang="pug">
.cart-content(
    :class="{ show: !cartHidden }",   
    )  
  .cart-container
    .container
      .row
        .col-xs-none.col-sm-1.col-md-1.col-lg-2
        .col-xs-12.col-sm-10.col-md-10.col-lg-8
          
          .cart-item.not-found(
            v-if="store.isCartEmpty"
          ) Набір порожній
          
          .cart-item(
            v-for="(obj, objKey) in store.bulkData", :key = "objKey"
            ) 
            p {{ obj.pdfName }}    
              span {{obj.svgSizeWidth}}x{{obj.svgSizeHeight}} мм, {{withFixers(objKey)}}
              | {{pdfPreview(objKey)}}

            
            .remove(
              @click = "deletePdf(objKey, false)"
              title="Видалити") ×
            
            .item(:style="'height: '+ obj.svgSizeHeight/2 +'px'" v-html="svgPreview[objKey]")

        .col-xs-none.col-sm-1.col-md-1.col-lg-2  

  .cart-header
    a.back(@click="hideCartList") ← генерувати ще
    h2 Набір табличок
    div.control &nbsp;
      a.removeAll(v-if="!store.isCartEmpty", @click="deletePdf(objKey, true)") очистити набір

  .cart-footer
    button.btn.btn-lg.btn-primary(
      v-if="!store.isCartEmpty"
      type="button",
      @click="downloadBulk()"
      ) Завантажити набір
  

.cart(:class="{ empty: store.isCartEmpty }", @click="showCartList", title="Набір покажчиків") {{store.bulkData.length}}
</template>

<script>
// State engine
import { store } from '@/store.js';

import * as pdfFuncs from '@/svgToPdfConvertJS.js';

export default {
  name: 'BulkDownloadList',

  data () {
    return {
      store,
      cartHidden: true,
      svgPreview: [],

    }
  },

  watch:
  {
    cartHidden: function () {
      document.body.style.overflow = this.cartHidden ? '' : 'hidden';
    }
  },

  methods:
  {
    pdfPreview(pdfNum)
    {
      this.svgPreview[pdfNum] = store.bulkData[pdfNum].svgPrev;
    },

    hideCartList() 
    {
      this.svgPreview = [];
      this.cartHidden = true; 
    },

    showCartList()
    {
      this.cartHidden = false;
    },

    downloadBulk()
    {
      pdfFuncs.pdfBulkDownload(this.store.bulkData);
      this.cartHidden = true;
    },

    withFixers(pdfNum)
    {
      if(store.bulkData[pdfNum].pdfName.includes('FIX'))
      {
        return 'позначки кріплення';
      }
      else
      {
        return 'без позначок';
      }
    },

    deletePdf(pdfNum, all)
    {
      if(all)
      {
        store.bulkData = [];
      }
      else
      {
        store.bulkData.splice(pdfNum, 1);
      }
    }
  },  
}
</script>
<style lang="scss">
@import '@/assets/styles/_svg-templates.scss';

.cart-container {
  .cart-item {
    .item {
       .requirements {
        display: none;
       }
       & > svg {
        height: 100%;

       }
    }
  }
}
</style>

<style lang="scss" scoped>

.cart {
  display: block;  
  width: 40px;
  height: 40px;

  position: absolute;
  top: 55px;
  right: 40px;

  border-radius: 40px;  

  background-color: $bsBlue;
  color: $White;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  z-index: 1001;
  &.empty {
    background-color: rgba($DarkGrey, .5);
  }
}

.cart-content {
  display: none;
  position: fixed;
  
  width: calc(100vw - 360px);
  height: 100vh;
  padding: 0;
  // padding: 60px 10px 20px;

  top: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba($White, 1);
  border-left: 1px solid $Paper;

  @include respond-above(xs) {
    width: 70%;        
  }

  @include respond-above(md) {
    width: calc(100vw - 360px);
  }
  
  &.show {
    display: block;
  }
}
.cart-header {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 60px 90px 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255,255,255,.8);
  backdrop-filter: blur(5px);
  .control {
    text-align: right;
  }
  .back, .removeAll {
    font-size: 14px;
    cursor: pointer;   
  }
  .removeAll {
    text-align: right;
  }

  h2 {
    margin: 0 -45px 0 0;  
    font-family: DniproOpen, sans-serif;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
  }
  * {
    min-width: 20%;
  }
}

.cart-footer {
  position: absolute;
  padding: 20px 0;
  margin: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: rgba(255,255,255,.8);
  backdrop-filter: blur(5px);
  .btn {
    margin: 0 auto;
  }
}
.cart-container {
  height: 100vh;
  padding: 110px 0;
  overflow: auto;
}
.cart-item {
  text-align: left;
  display: block;
  position: relative;
  border-radius: 12px;
  padding: 20px;
  transition: background-color .3s ease-out;  
  p {
    font-size: 14px;
    color: #000;
    font-weight: bold;
    span {
      display: block;
      font-size: 12px;
      font-weight: normal;
      opacity: .7;
    }
  }
  &:hover:not(.not-found) {
    background-color: #FFF3CD;
    transition: background-color .3s ease-out;
    .remove {
      display: block;
      color: #000;
      border-color: rgba(0,0,0,1);
      opacity: .7;
      transition: all .3s ease-out;
       &:hover {
        background-color: #ff0000;
        border-color: #ff0000;
        color: #fff;
        opacity: .7 ;
        transition: all 200ms ease-out;
      }
    }
  }
  &.not-found {
    text-align: center;
  }

  .item {   
    display: block;
    height: 50px;
    margin: 0;
    border: none;
    svg {
      display: block;
      height: 50px !important;
    }
  }  

  .remove {
    width: 26px;
    height: 26px;
    display: block;
    padding: 0;

    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    
    border: 2px solid rgba(0,0,0,0);
    border-radius: 18px;

    text-align: center;
    font-size: 24px;
    line-height: 19px;
    vertical-align: middle;
    text-indent: 1px;
    
    opacity: .2;
    transition: all .3s ease-out;    
  }
}
.btn {
  margin: 10px auto;
}

</style>